import { useTheme } from 'next-themes';
import { Button } from "../../ui/button";
import { Copy, RotateCw, Download } from 'lucide-react';
import { Toaster, toast } from 'sonner';
// icons
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ImageIcon from '@mui/icons-material/Image';
import pdfIcon from '../../Assets/pdf-icon (1).png';
import pptIcon from '../../Assets/ppt-icon.png';
import csvIcon from '../../Assets/csv-icon.png';
import docs from '../../Assets/docx-icon.png';
import nightShare from '../../Assets/nightShare.png';
import lightShare from '../../Assets/lightShare.png';
// css
import './index.scss';


const getYouTubeEmbedUrl = (text) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = text.match(youtubeRegex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};
const getFileTypeIcon = (type) => {
    switch (type) {
        case 'application/pdf':
            return <img src={pdfIcon} alt="PDF Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <img src={docs} alt="Word Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <img src={csvIcon} alt="Excel Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <img src={pptIcon} alt="PPT Icon" width={20} height={20} />;
        case 'image/png':
        case 'image/jpeg':
            return <ImageIcon size={20} />;
        case 'application/octet-stream':
            return <DocumentScannerIcon size={20} />;
        default:
            return null;
    }
};
const Index = ({ userMessages }) => {
    const { theme } = useTheme();

    const handleCopyText = (text) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('Copy');
        textField.remove();
        toast.success("Text Copied");
    };
    const handleDownload = (file) => {
        // Initiate file download
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(`${file.name} downloaded!`);
    };

    return (
        <div className="build-up-transcript-container">
            <div className="flex-grow p-4 space-y-2 build-up-transcript-chat">
                {userMessages?.map((message, index) => {
                    message.sender = message?.isUser ? "user" : "bot";
                    message.text = message?.payload?.text;
                    
                    const youtubeUrl = '' //getYouTubeEmbedUrl(message.text);
                    return (
                        <>
                            <div key={index} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                                <div className={`${message.sender === "user" ? "build-up-user-chat" : "build-up-bot-chat"} ${theme === "dark" ? "bg-[#2A2A2A] text-[#FFFFFF]" : "bg-[#FFFFFF]"}`}>
                                    {youtubeUrl ? (
                                        <div style={{ width: '100%' }}>
                                            <div style={{ marginBottom: "10px", marginTop: "15px" }}>
                                                Here's a link to the Yes Theory YouTube channel: <br /><a href="https://www.youtube.com/c/YesTheory" target="_blank" rel="noopener noreferrer" style={{ color: "#F1F894" }}>Yes Theory - YouTube (Youtube)</a>.
                                            </div>
                                            <iframe
                                                style={{ marginTop: "5px", marginBottom: "5px", width: "368px", height: "240px" }}
                                                src={youtubeUrl}
                                                frameBorder="0"
                                                allowFullScreen
                                                title="YouTube Video"
                                            ></iframe>
                                            <div style={{ marginTop: "20px" }}>
                                                <span style={{ fontWeight: 550, fontSize: "15px", lineHeight: "18px" }}>Additionally, you can watch one of their recent videos, "Living 5 DAYS in Complete Darkness," here: <a href="https://www.youtube.com/watch?v=VIDEO_ID" target="_blank" rel="noopener noreferrer">Living 5 DAYS in Complete Darkness - YouTube</a>.</span>
                                            </div>
                                        </div>
                                    ) : message?.file ? (
                                        <div className="flex items-center">
                                            {getFileTypeIcon(message.file.type)}
                                            <span className='ms-2'>{message.file.name}</span>
                                            <Button variant="ghost" size="icon" onClick={() => window.open(message.file.url, '_blank')}>
                                                <Download size={20} onClick={() => handleDownload(message.file)} />
                                            </Button>
                                        </div>
                                    )
                                        : (
                                            message.text
                                        )}
                                </div>
                            </div>
                            {message?.sender === "bot" && (
                                <div className={`${theme === "dark" ? "build-up-icons-btn-dark" : "build-up-icons-btn-light"} flex space-x-2   gap-2`}>
                                    <Copy size={18} onClick={() => handleCopyText(message?.text)} style={{ marginLeft: "5px" }} />
                                    <RotateCw size={18} />
                                    <img
                                        src={theme == "dark" ? nightShare : lightShare}
                                        style={{ height: "18px", width: "18px", cursor: "pointer" }} />
                                </div>

                            )}
                        </>
                    );
                })}
            </div>
            {/* <InputBase /> */}
            <Toaster position="top-center" />
        </div>
    );
};

export default Index;