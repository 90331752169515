import React, { useRef, useEffect } from 'react';
import { ReactComponent as BifilesIcon } from '../../../Assets/icons/bi_files.svg';
import './comps-style.scss';

const AttachFile = ({ open, handleClose, uploadFile }) => {
    const wrapperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (event.target.id == 'clip-btn') {
            return;
        }
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!open) return

    return (
        <div ref={wrapperRef} className="bu-popover-wrapper">
            <div className="bu-file-upload">
                <BifilesIcon className="bu-icon" />
                <p>Drop files here, <u>browse files</u> Supports pdf, ppt, txt, docx</p>
                <span>Max 10 files upto 50 MB each</span>
            </div>
            <div className="bu-url-field">
                <input type="text" placeholder='https://example.com' />
                <button className="bu-btn" onClick={() => uploadFile(true)}>
                    Attach
                </button>
            </div>
        </div>
    )
}

export default AttachFile
