import React, { useState } from 'react';
import { useTheme } from 'next-themes';

import {
    Card,
} from "../../ui/card"
import { ReactComponent as ChatIcon } from '../../Assets/icons/chat-icon.svg';
import { ReactComponent as GlobeIcon } from '../../Assets/icons/glob-icon.svg';
import { ReactComponent as AddImageIcon } from '../../Assets/icons/image-add.svg';
import { ReactComponent as CodeIcon } from '../../Assets/icons/code.svg';
import { ReactComponent as DocIcon } from '../../Assets/icons/documents.svg';

import './index.scss';


const Index = ({ setShowMainScreen }) => {
    const { theme } = useTheme();

    const [file, setFile] = useState();
    const [image, setImage] = useState();

    return (
        <>
            <div className="build-up-main-container">
                <div className="flex-grow flex items-center p-2">
                    <div className="build-up-main-content grid grid-cols-2 gap-3 p-4 " >
                        <Card className="build-up-feature-button flex items-center justify-center p-4 "
                            onClick={() => {
                                setShowMainScreen(false)
                            }}
                        >
                            <div className="flex items-center" style={{ width: "182px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <div style={{ width: 24, height: 24 }}><ChatIcon width={24} height={24}/></div>
                                <p style={{ fontSize: "16px" }}>Chat with this Webpage</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "155px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <div style={{ width: 24, height: 24 }}><GlobeIcon width={24} height={24}/></div>
                                <p style={{ fontSize: "16px" }}>Chatbots</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "182px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <div style={{ width: 24, height: 24 }}><AddImageIcon width={24} height={24}/></div>
                                <p style={{ fontSize: "16px" }}>Generate Images</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "155px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <div style={{ width: 24, height: 24 }}><CodeIcon width={24} height={24}/></div>
                                <p style={{ fontSize: "16px" }}>Code Interpreter</p>
                            </div>
                        </Card>


                        <Card className="build-up-feature-button flex items-center justify-center space-x-2 col-span-2 text-center "
                            style={{ width: "451px", height: "85px" }}
                            onClick={() => {
                                setShowMainScreen(false)
                            }}>
                            <div style={{ width: 24, height: 24 }}><DocIcon width={24} height={24}/></div>
                            <span style={{ fontSize: "16px" }}>Chat with your Documents</span>
                        </Card>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Index;
