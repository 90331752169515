import client from "../axios";
import { GET_ENROLLMENT, SEND_MESSAGE_CODE, LOGIN } from "../Endpoints/AllEndPoints";

export const getAuthFromToken = async (payload) => {
    return await client.post(LOGIN, payload);
};

export const generateCode = async (payload) => {
    return await client.get(GET_ENROLLMENT);
};

export const SendMessage = async (payload) => {
    return await client.post(SEND_MESSAGE_CODE, payload);
};
