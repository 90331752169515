import InputBase from '../Common/InputBase';
import './footer.scss';

const Footer = ({
    sendMessage,
    setInput,
    input,
    sendMessageLoading
}) => {
    return (
        <InputBase
            input={input}
            sendMessageLoading={sendMessageLoading}
            sendMessage={sendMessage}
            setInput={setInput}
        />
    )
}

export default Footer