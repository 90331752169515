import React, { useRef, useEffect } from 'react';
import { ReactComponent as AddIcon } from '../../../Assets/icons/add-icon.svg';
import { ReactComponent as DriveIcon } from '../../../Assets/icons/drive-icon.svg';
import { ReactComponent as OnedriveIcon } from '../../../Assets/icons/onedrive-icon.svg';
import './comps-style.scss';

const ImageUpload = ({ open, handleClose, uploadFile }) => {
    const wrapperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (event.target.id == 'image-btn') {
            return;
        }
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!open) return

    return (
        <div ref={wrapperRef} className="bu-popover-image-upload-wrapper">
            <button className="bu-btn light-btn">
                <DriveIcon />
                Connect to Google Drive
            </button>
            <div className="divider" />
            <button className="bu-btn light-btn">
                <OnedriveIcon />
                Connect to Microsoft OneDrive
            </button>
            <button className="bu-btn" style={{ marginTop: '12px' }} onClick={() => uploadFile(true)}>
                <AddIcon className="bu-icon" />
                Upload from computer
            </button>
        </div >
    )
}

export default ImageUpload
