import './App.css';
import MainPage from './Components/MainPage';
import Transcripts from './Components/Transcripts/index'
import Layout from './Components/Layout';
import { ThemeProvider } from 'next-themes';
import { useEffect, useState } from 'react';
import { getAuthToken } from './utils/methods';
import Login from './Components/Auth';
import { SendMessage, generateCode, getAuthFromToken } from './utils/Chat/chatIntegrationApi';
import Pusher from 'pusher-js';
import { toast } from 'sonner';
import repat from '../src/Assets/repat.png'
import chat from '../src/Assets/icons/chat-icon-Photoroom.png'

function App() {
  const [showFloatingWindow, setShowFloatingWindow] = useState(false);
  const [showMainScreen, setShowMainScreen] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(getAuthToken());

  //chat Handlers
  const [sendMessageLoading, setSendMessageLoading] = useState(true);
  const [channelId, setChannelId] = useState('');
  const [input, setInput] = useState('');
  const [userMessages, setUserMessages] = useState([]);

  const channel = new BroadcastChannel('build-up-extension-channel');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams?.get('token');

    if (token) {
      setTimeout(() => {
        window.postMessage({
          type: "BU_AUTH_TOKEN",
          token: token
        }, "*");

        window.location.href = window.location.origin
      }, 1000)
    }

    window.addEventListener("message", (event) => {
      if (event.data.type === "BU_TOKEN") {
        localStorage.setItem("bu-auth-token", event.data.token)
        channel.postMessage({ action: 'AuthChanged' });
      }
    });
  }, []);

  channel.onmessage = async (event) => {
    if (event?.data?.action === "AuthChanged") {
      setIsAuthorized(getAuthToken())
    }
  };

  useEffect(() => {
    setIsAuthorized(getAuthToken());
  }, [showFloatingWindow])

  useEffect(() => {
    const checkLocalStorage = () => {
      const showFloatingScreenStored = localStorage.getItem('showFloatingScreen') === 'true';
      if (showFloatingScreenStored !== showFloatingWindow) setShowFloatingWindow(showFloatingScreenStored);
    };

    checkLocalStorage();
    const intervalId = setInterval(checkLocalStorage, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      getChannelID();
    }
  }, [isAuthorized])

  useEffect(() => {
    if (channelId) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_TOKEN, {
        cluster: 'us2'
      });

      const channel = pusher.subscribe(channelId);

      channel.bind('message', (data) => {
        setUserMessages((prevMessages) => [...prevMessages, data]);
      });

      return () => {
        pusher.unsubscribe(channelId);
      };
    }
  }, [channelId]);

  // get channel Id
  const getChannelID = async () => {
    let channelId = '';
    setSendMessageLoading(true)
    try {
      const token = getAuthToken();
      if (token?.length === 36) {
        await getAuthTokenFromDB(token);
      }
      const res = await generateCode({});
      channelId = res?.data?.id
      setChannelId((channelId || 0)?.toString());
    }
    catch (error) {
      toast.warning("Error while retrieving channel ID")
    }
    finally {
      setSendMessageLoading(false)
    }

    return channelId;
  }

  const sendMessage = async () => {
    if (input && input?.trim().length > 0 && !sendMessageLoading) {
      setSendMessageLoading(true);
      setShowMainScreen(false)
      const userObj = {
        type: "text",
        text: input
      }

      try {
        setInput('');
        await SendMessage(userObj);
      }
      catch (error) {
        toast.warning("Error while sending message")
      }

      setSendMessageLoading(false);
    }
  }

  const getAuthTokenFromDB = async (token) => {
    const { data } = await getAuthFromToken({ token });
    if (data?.token) {
      localStorage.setItem("bu-auth-token", data?.token)
    }
  }

  return (
    <>
      <ThemeProvider attribute="class">
        {/* <div style={{backgroundImage: 'url(../src/Assets/repat.png)', backgroundRepeat: "no-repeat", height: "100vh"}}> */}
      {/* <iframe src="https://www.repatarmenia.org/" style={{height: "100vh", width: "100%"}} title='repat-armenia'></iframe> */}
      {/* <image src={"../src/Assets/repat.png"} style={{height: "100vh", width: "100%"}} /> */}
        {/* {isAuthorized ? */}
        <div style={{ 
  backgroundImage: 'url("../src/Assets/repat.png")', 
  height: "100vh", 
  width: "100%", 
  backgroundSize: "cover", 
  backgroundPosition: "center" 
}} >
          <Layout
            showFloatingWindow={showFloatingWindow}
            input={input}
            sendMessageLoading={sendMessageLoading}
            setShowFloatingWindow={setShowFloatingWindow}
            setShowMainScreen={setShowMainScreen}
            sendMessage={sendMessage}
            setInput={setInput}
          >

            {showMainScreen ?
              <MainPage setShowMainScreen={setShowMainScreen} />
              :
              <Transcripts userMessages={userMessages} />
            }
          </Layout>
          {/* :
          <Login />
        } */}
        {/* </div> */}

        </div>

      </ThemeProvider>

    </>
  );
}

export default App;
